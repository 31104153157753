.App {
  background-color: #172430;
  color:white;
  height: 100vh;
  overflow: auto;
  text-align: center;
}

.center {
  text-align: center;
}

.login-button{
  text-align: center;
  padding: 100px 0 20px;
}

.account-button{
  float: right;
  height: 6vh;  /* must equal navbar height */
}

.button-size{
  height: 6vh;  /* must equal navbar height */
}

.navbar{
  background-color: black;
  color: white;
  width: 100%;
  height: 6vh;
  text-align: left;
}

.drawer{
  background-color: rgb(41, 37, 37);
  color: white;
  height: 100vh;
  width: 250px;
}

.iframe {
  display: block;
  width: 100vw;
  height: 94vh;  /* must equal 100-(navbar height) to fill screen */
  border: 0;
}

/*********************
 * Transactions Table 
 *********************/

.transactions{
  height: 94vh;     /* must equal 100-(navbar height) to fill screen */
  overflow: auto;   /* fills screen */
  padding: 0 10px;  /* padding on sides */
  text-align: center;
  font-size: calc(10px + 1vmin);
}

.transactions-title{
  margin-bottom: 0;
 }

.table-select-label{
  font-size: calc(4px + 1vmin);
  text-align: left;
  margin: 0;
}

.display-flex{
  display: flex;
}

.flex2{
  text-align: left;
  flex: 2;
}

/* Styling for the orders depending on their status */
.status-COMPLETED{
  color: green;
}
.status-FAILED{
  color: red; 
}
.status-CANCELLED{
  color: gray;
}

/* Transaction Selection Display */
.transaction-display{
  text-align: left;
  padding: 0 10px 20px;
}

#transaction-map{
  width: 100%;
  height: 45vh;
}

.margin-top{
  margin-top: 20px;
}

.warning-color{
  color: #ed6c02;
}

.failed-color{
  color: red;
}

.align-left{
  text-align: left;
  margin-top: 5px;
}

.display-sql {
  white-space: pre-wrap;
  font-size: calc(10px + 0.5vmin);
  padding-bottom: 20px;
}

.history-transaction-title {
  display: flex;
  align-items: center;
  padding-top: 20px;
}
